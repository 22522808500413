
  import { prismicLink } from '@/utils/url'
  import NavigationDropdownMenu from './NavigationDropdownMenu.vue'

  export default {
    name: 'Navigation',
    fetchKey: 'navigation',
    props: {
      loginLink: {
        type: Object,
        default: () => {
          return { url: '', target: '', rel: '', label: '' }
        },
      },
      promotionLink: {
        type: Object,
        default: () => {
          return { url: '', target: '', rel: '', label: '' }
        },
      },
    },
    async fetch() {
      await this.$prismic.api.getByUID('navigation', 'main').then((res) => {
        if (res.data) {
          this.navigation = res.data
        }
      })
      this.navigationLinks = this.setNavigationLinks()
    },
    data() {
      return {
        windowWidth: 0,
        mainMenuOpen: false,
        mdBreakpoint: 800,
        navigation: {},
        navigationLinks: [],
      }
    },
    methods: {
      updateWindowSize() {
        this.windowWidth = window ? window.innerWidth : 0
        this.menuOpen =
          this.windowWidth > this.mdBreakpoint ? false : this.mainMenuOpen
      },
      toggleMainMenu() {
        this.mainMenuOpen = !this.mainMenuOpen
      },
      toggleSubMenu(index) {
        this.navigationLinks.forEach((link, i) => {
          if (i !== index) {
            link.menuOpen = false
          }
        })
        this.navigationLinks[index].menuOpen =
          !this.navigationLinks[index].menuOpen
      },
      setNavigationLinks() {
        if (this.navigation.nav) {
          return this.navigation.nav.map((navItem) => {
            let navLink = {
              ...prismicLink(
                navItem.primary.link,
                this.$prismic.asText(navItem.primary.label)
              ),
              subMenuLinkLabel: this.$prismic.asText(
                navItem.primary.sub_menu_link_label
              ),
              subLinks: [],
            }
            if (navItem.items.length > 0) {
              navItem.items.forEach((item) =>
                navLink.subLinks.push({
                  ...prismicLink(
                    item.sub_nav_link,
                    this.$prismic.asText(item.sub_nav_link_label)
                  ),
                  icon: {
                    name: this.$prismic.asText(item.icon_name) || '',
                    color: item.icon_color || '',
                  },
                })
              )
              navLink.menuOpen = false
            }
            return navLink
          })
        }
        return []
      },
    },
    components: { NavigationDropdownMenu },
    mounted() {
      this.updateWindowSize()
      window.addEventListener('resize', this.updateWindowSize)
    },
    beforeDestroyed() {
      window.removeEventListener('resize', this.updateWindowSize)
    },
  }

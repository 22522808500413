/**
 * html serializer for prismic based content, prismic will use this serializer by default
when consuming content in a primsic field component.

 * The default function is the main html serializer that prismic uses based on the project configuration,
 * This plugin also includes custom serializers as well for different use cases that are exported as individual utility functions

 * To learn more about HTML Serializer check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/html-serializer
*/
import linkResolver from './link-resolver'
import prismicDOM from 'prismic-dom'

const Elements = prismicDOM.RichText.Elements

// main html serializer for prismic, any additional serializations that do not need to be extracted into individual serializers should live in this main function
export default function (type, element, content, children) {
  if (type === Elements.paragraph && element.spans.length > 0) {
    if (
      element.spans.some(
        (span) =>
          span.type === Elements.label && span.data.label === 'left-align'
      )
    ) {
      return `<p class="left-align">${children}</p>`
    }

    if (
      element.spans.some(
        (span) =>
          span.type === Elements.label && span.data.label === 'right-align'
      )
    ) {
      return `<p class="right-align">${children}</p>`
    }
  }

  if (type === Elements.list || type === Elements.oList) {
    let listType = type === Elements.oList ? 'ol' : 'ul'
    if (children.some((child) => child.includes('class="left-align"'))) {
      return `<${listType} class="left-align">${children.join(
        ''
      )}</${listType}>`
    }

    if (children.some((child) => child.includes('class="right-align"'))) {
      return `<${listType} class="right-align">${children.join(
        ''
      )}</${listType}>`
    }
  }

  // Generate links to Prismic Documents as <router-link> components
  // Present by default, it is recommended to keep this
  if (type === Elements.hyperlink) {
    let result = ''
    const url = prismicDOM.Link.url(element.data, linkResolver)

    if (element.data.link_type === 'Document') {
      result = `<a href="${url}">${content}</a>`
    } else {
      const target = element.data.target
        ? `target="'${element.data.target}'" rel="noopener"`
        : ''
      result = `<a href="${url}" ${target}>${content}</a>`
    }
    return result
  }

  // If the image is also a link to a Prismic Document, it will return a <router-link> component
  // Present by default, it is recommended to keep this
  if (type === Elements.image) {
    let result = `<sb-image src="${element.url}" alt="${
      element.alt || ''
    }" copyright="${element.copyright || ''}" width="${
      element.dimensions.width
    }" height="${element.dimensions.height}"></sb-image>`

    if (element.linkTo) {
      const url = prismicDOM.Link.url(element.linkTo, linkResolver)

      if (element.linkTo.link_type === 'Document') {
        result = `<a href="${url}">${result}</a>`
      } else {
        const target = element.linkTo.target
          ? `target="${element.linkTo.target}" rel="noopener"`
          : ''
        result = `<a href="${url}" ${target}>${result}</a>`
      }
    }
    const wrapperClassList = [element.label || '', 'block-img']
    result = `<p class="${wrapperClassList.join(' ')}">${result}</p>`
    return result
  }

  // Return null to stick with the default behavior for everything else
  return null
}

/*
 * Individual serializer use cases are listed below,
 * this includes functions such as the custom slice heading serializer, navigation links serializer, etc
 */

/**
 * prismic serializer to handle the dynamic headings where on mobile viewports,
 * the font weight is set to medium to highlight a key word, and on larger viewports it is all bold
 */
export function headingSerializer(type, element, content, children) {
  //if the element is a heading2,
  if (type.includes('heading') && element.text) {
    let tag
    switch (type) {
      case 'heading2':
        tag = 'h2'
        break
      case 'heading3':
        tag = 'h3'
        break
      case 'heading4':
        tag = 'h4'
        break
      case 'heading5':
        tag = 'h5'
        break
      case 'heading6':
        tag = 'h6'
        break
      default:
        break
    }
    //return the styled HTML
    const id = element.text
      .replace(/^\s+|\s+$/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-')
    return `<${tag} id=${id}>${children.join(
      ''
    )} <a class="heading-permalink" href="#${id}" aria-hidden="true" tabindex="-1" title="Link to Section"><span><svg fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" focusable="false" height="1em" width="1em"><path data-v-4a514f02="" d="M9.199 13.599a5.99 5.99 0 0 0 3.949 2.345 5.987 5.987 0 0 0 5.105-1.702l2.995-2.994a5.992 5.992 0 0 0 1.695-4.285 5.976 5.976 0 0 0-1.831-4.211 5.99 5.99 0 0 0-6.431-1.242 6.003 6.003 0 0 0-1.905 1.24l-1.731 1.721a.999.999 0 1 0 1.41 1.418l1.709-1.699a3.985 3.985 0 0 1 2.761-1.123 3.975 3.975 0 0 1 2.799 1.122 3.997 3.997 0 0 1 .111 5.644l-3.005 3.006a3.982 3.982 0 0 1-3.395 1.126 3.987 3.987 0 0 1-2.632-1.563A1 1 0 0 0 9.201 13.6zm5.602-3.198a5.99 5.99 0 0 0-3.949-2.345 5.987 5.987 0 0 0-5.105 1.702l-2.995 2.994a5.992 5.992 0 0 0-1.695 4.285 5.976 5.976 0 0 0 1.831 4.211 5.99 5.99 0 0 0 6.431 1.242 6.003 6.003 0 0 0 1.905-1.24l1.723-1.723a.999.999 0 1 0-1.414-1.414L9.836 19.81a3.985 3.985 0 0 1-2.761 1.123 3.975 3.975 0 0 1-2.799-1.122 3.997 3.997 0 0 1-.111-5.644l3.005-3.006a3.982 3.982 0 0 1 3.395-1.126 3.987 3.987 0 0 1 2.632 1.563 1 1 0 0 0 1.602-1.198z"></path></svg></span></a></${tag}>`
  }

  return null
}


  import { prismicLink } from '@/utils/url'
  import SliceZone from 'vue-slicezone'

  export default {
    name: 'SiteFooter',
    fetchKey: 'footer',
    async fetch() {
      await this.$prismic.api
        .getSingle('footer')
        .then((res) => {
          if (res.data) {
            this.footer = res.data
          }
        })
        .catch((e) => console.log(e))
    },
    data() {
      return {
        footer: {},
        windowWidth: 0,
      }
    },
    computed: {
      slices() {
        if (this.footer && this.footer.slices) {
          return this.footer.slices.filter(
            (item) => item.slice_type && item.slice_type !== 'nav_item'
          )
        }
        return []
      },
      rowCount() {
        if (this.links && this.links.length > 0) {
          if (this.windowWidth < 800) {
            let parentLinks = this.links.length
            let childLinks = this.links.reduce((links, link) => {
              if (link.subLinks) {
                return links
                  ? links + link.subLinks.length
                  : link.subLinks.length
              }
            }, 0)
            return Math.ceil((parentLinks + childLinks) / 2)
          }
        }
        return 4
      },
      links() {
        if (this.footer && this.footer.slices) {
          return this.footer.slices
            .filter((item) => item.slice_type === 'nav_item')
            .map((footerItem) => {
              let footerLink = {
                ...prismicLink(
                  footerItem.primary.link,
                  this.$prismic.asText(footerItem.primary.label)
                ),
              }

              if (footerItem.items.length > 0) {
                let subLinks = footerItem.items.map((item) => {
                  let subLink = {
                    ...prismicLink(
                      item.sub_nav_link,
                      this.$prismic.asText(item.sub_nav_link_label)
                    ),
                    icon: {
                      name: this.$prismic.asText(item.icon_name) || '',
                      color: item.icon_color || '',
                    },
                  }

                  return subLink
                })
                footerLink.subLinks = subLinks
              }
              return footerLink
            })
        }
        return []
      },
      tagline() {
        return this.footer && this.footer.tagline
          ? this.$prismic.asText(this.footer.tagline)
          : ''
      },
      copyright() {
        return this.footer && this.footer.copyright
          ? `${this.$prismic.asText(
              this.footer.copyright
            )} ${new Date().getFullYear()}`
          : ''
      },
    },
    methods: {
      updateWindowSize() {
        if (typeof window !== 'undefined') {
          this.windowWidth = window.innerWidth
        }
      },
    },
    mounted() {
      this.updateWindowSize()
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', this.updateWindowSize)
      }
    },
    beforeDestroyed() {
      window.removeEventListener('resize', this.updateWindowSize)
    },
    components: {
      SliceZone,
    },
  }

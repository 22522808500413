export function youtubeIdParser(url) {
  const regExp =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[1] ? match[1] : false
}

export function slugify(text) {
  return text
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
}

// return a link object from a prismic source
export function prismicLink(link, label) {
  if (link) {
    return {
      url: link.url ? link.url : '/',
      target: link.target ? link.target : null,
      rel: link.target ? 'noopener noreferrer' : null,
      label,
    }
  }

  return {
    url: '/',
    target: '',
    rel: '',
    label: '',
  }
}


  import { prismicLink } from '@/utils/url'

  export default {
    name: 'SiteHeader',
    fetchKey: 'header',
    async fetch() {
      await this.$prismic.api.getByUID('header', 'siteheader').then((res) => {
        if (res.data && res.data.slices.length > 0) {
          this.header = res.data.slices[0].primary
        }
      })
    },
    data() {
      return {
        header: {},
        backgroundColor: 'var(--color-bg-primary)',
      }
    },
    computed: {
      logo() {
        return this.header.logo
          ? {
              src: {
                url: this.header.logo.url,
                alt: this.header.logo.alt,
              },
              width: 240,
              height: 40,
              lazy: false,
            }
          : {}
      },
      logoSmall() {
        return this.header.logoIcon
          ? {
              src: {
                url: this.header.logoIcon.url,
                alt: this.header.logoIcon.alt,
              },
              width: 40,
              height: 40,
              lazy: false,
            }
          : {}
      },
      loginLink() {
        return this.header.loginLink
          ? prismicLink(
              this.header.loginLink,
              this.$prismic.asText(this.header.loginLinkLabel)
            )
          : {}
      },
      promotionLink() {
        return this.header.promotionLink
          ? prismicLink(
              this.header.promotionLink,
              this.$prismic.asText(this.header.promotionLinkLabel)
            )
          : {}
      },
    },
    mounted() {
      let firstSection = document.querySelector('section')
      let bgColor = null
      // check if we have a section
      if (firstSection !== null) {
        bgColor = window
          .getComputedStyle(firstSection)
          .getPropertyValue('background-color')
      }
      this.backgroundColor = !bgColor ? 'var(--color-bg-primary)' : bgColor
      window.onscroll = () => {
        if (window.scrollY > 100) {
          this.backgroundColor = 'var(--color-bg-primary)'
        } else {
          this.backgroundColor = !bgColor ? 'var(--color-bg-primary)' : bgColor
        }
      }
    },
  }

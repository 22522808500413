/**
 * default link resolver that prismic uses to resolve links in rich-text fields or prismic-link elements
 * @param {*} doc
 * @returns
 */

export default function (doc) {
  if (doc.isBroken) {
    return '/error'
  }

  if (doc.type === 'page' && doc.uid === 'home') {
    return '/'
  }

  return null
}

/**
 * Additional Link Resolver for the navigation links as they are a custom prismic type: 'nav_item',
 * they need to be resolved slightly different based on the link_type value.
 * It looks at if link_type is of type 'Document', which is a prismic type, and if there is a parent nav item, and if so it concatenates that to the full link path
 * Otherwise it will return the full url as a string

 * @param { Object } item
 * @param { Object } parentItem
 * @returns String
 */
export function navigationLinkResolver(item, parentItem = null) {
  if (item.link_type === 'Document') {
    if (!item.data && !parentItem) {
      return `/${item.uid}`
    } else {
      return parentItem.uid ? `/${parentItem.uid}/${item.uid}` : `/${item.uid}`
    }
  }

  return `${item.url}`
}
